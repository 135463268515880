<template>
  <div class="nav">
    <div class="toptips" v-show="istips == true">
      By continuing to use our website and/or services you agree to the updated
      Privacy Policy and the use of cookies as well as our &nbsp;<router-link to="term">Terms and
        Conditions</router-link>
      <!-- <i class="el-icon-close" @click="closexy()"></i> -->
      <div class="agree" @click="closexy()">Agree</div>
    </div>
    <div class="nav_main">
      <router-link to="/home">
        <div class="logo">
          <img :src="pageConfig.logo" @load="imgLoad" />
        </div>
      </router-link>

      <div class="menu" v-if="pageConfig.id == 8">
        <div class="menu">
          <div class="item"><router-link to="/"> Flights </router-link></div>
          <!-- <div class="item"><router-link to="/about">About Us</router-link></div> -->
          <div class="item" style="display: none">
            <a href="https://travel.aoflights.com/en/">Travel</a>
          </div>
          <!-- <div class="item">about</div> -->
          <div class="item" v-if="loginShow">
            <router-link to="/orderSearch">View My bookings</router-link>
          </div>
        </div>
        <div @click="drawer = true" class="tnzfj" style="margin-left: 6px">
          <img src="@/assets/img/airpl.png" alt="" />
          <span style="font-size: 16px; margin-left: 6px" class="el-icon-arrow-up" v-if="drawer"></span>
          <span style="font-size: 16px; margin-left: 6px" class="el-icon-arrow-down" v-else></span>
        </div>
        <el-drawer class="sortDrawer-box" :visible.sync="drawer" :direction="direction">
          <div class="sortDrawer">
            <div class="sortDrawer-item" @click="tiaozhuan(1)">
              <img src="@/assets/img/topair.png" alt="" />Flights
            </div>
            <div class="sortDrawer-item" @click="tiaozhuan(2)">
              <img src="@/assets/img/tclvyou.png" alt="" />
              Travel
            </div>
            <div class="sortDrawer-item" v-if="loginShow" @click="tiaozhuan(3)">
              <img src="@/assets/img/tcchaxun.png" alt="" />
              Search Trips
            </div>
          </div>
        </el-drawer>
        <div class="zw">
          <!-- 占位置 -->
        </div>
      </div>
      <div class="menu" v-else>
        <div class="item"><router-link to="/">Home</router-link></div>
        <!-- <div class="item"><router-link to="/about">About Us</router-link></div> -->
        <div class="item">
          <router-link to="/Contact">Contact Us</router-link>
        </div>
        <!-- <div class="item">about</div> -->
        <div class="item" v-if="loginShow">
          <router-link to="/orderSearch">View My bookings</router-link>
        </div>
      </div>

      <div class="dropDown">
        <div class="tips">24/7 Support</div>
        <div class="tphone">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="display: flex; align-items: center">
              <template v-if="pageConfig.id == 8">
                <!-- <i
                  class="el-icon el-icon-message"
                  style="margin-right: 5px; font-size: 17px"
                ></i> -->
                <svg t="1721026961719" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="5949" id="mx_n_1721026961720" width="20" height="20"
                  style="margin-right: 5px">
                  <path
                    d="M811.67 157.26H214.33C131.99 157.26 65 224.25 65 306.59v392c0 82.34 66.99 149.33 149.33 149.33h597.33c82.34 0 149.33-66.99 149.33-149.33v-392c0.01-82.34-66.98-149.33-149.32-149.33zM670.31 508.75c-0.12-0.12-0.25-0.23-0.38-0.35l216.4-188.83v379.02c0 7.09-1.01 13.94-2.87 20.44L670.31 508.75z m141.36-276.83c16.7 0 32.13 5.51 44.58 14.8L586.64 481.99c-41.97 36.59-105.29 36.62-147.28 0L169.75 246.73c12.45-9.3 27.89-14.81 44.59-14.81h597.33z m-669.14 487.1a74.524 74.524 0 0 1-2.87-20.43V319.57l216.4 188.83c-0.12 0.12-0.25 0.23-0.37 0.35L142.53 719.02z m71.8 54.24c-6.27 0-12.36-0.79-18.18-2.25l211.96-209.1c2.03-2 3.77-4.18 5.25-6.49 30.29 19.06 64.95 28.6 99.62 28.6 34.68 0 69.35-9.54 99.63-28.6a37.71 37.71 0 0 0 5.25 6.49l211.97 209.1a74.636 74.636 0 0 1-18.18 2.25H214.33z"
                    p-id="5950" fill="#0088b8"></path>
                </svg>
                {{ pageConfig.support_mail
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </template>
              <template v-else><i class="el-icon el-icon-phone"></i>
                {{ pageConfig.tel_from_overseas
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </template>
            </span>
            <el-dropdown-menu slot="dropdown" class="my-dropdown-menu">
              <el-dropdown-item class="pwid">
                <!-- <div class="textl">Sydney</div> -->
                <div class="textm" v-if="pageConfig.id != 8">
                  <div>General enquiries</div>
                  <div>From overseas</div>
                </div>
                <div class="textR">
                  <div style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                    ">
                    <svg t="1720778016909" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="1694" id="mx_n_1720778016910" width="18" height="18"
                      style="margin-right: 7px; transform: translate(0, -2px)" v-if="pageConfig.id == 8">
                      <path
                        d="M419.6 344l-88 89.6c45.8 101.7 143 215 255.7 253.1l76.1-75.8s180.3 31.5 220.8 51.1c35.2 17 46.8 54.8 41.3 101.4-5.8 50.1-71.5 160.5-189.7 161.7-118.2 1.2-292.3-87.9-417-219.2-124.7-131.5-235.9-298.1-218-439.5C118.5 124.9 241.7 99.6 272.5 97.3c31.2-2.3 55.9 6.8 74.7 30.1 24.2 30.1 72.4 216.6 72.4 216.6z"
                        fill="#333333" p-id="1695"></path>
                    </svg>
                    <span>{{ pageConfig.tel_general_enquiries }}</span>
                    <template v-if="pageConfig.id == 8">(Australia)</template>
                  </div>
                  <div style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                    ">
                    <svg t="1720778016909" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="1694" id="mx_n_1720778016910" width="18" height="18"
                      style="margin-right: 7px; transform: translate(0, 1px)" v-if="pageConfig.id == 8">
                      <path
                        d="M419.6 344l-88 89.6c45.8 101.7 143 215 255.7 253.1l76.1-75.8s180.3 31.5 220.8 51.1c35.2 17 46.8 54.8 41.3 101.4-5.8 50.1-71.5 160.5-189.7 161.7-118.2 1.2-292.3-87.9-417-219.2-124.7-131.5-235.9-298.1-218-439.5C118.5 124.9 241.7 99.6 272.5 97.3c31.2-2.3 55.9 6.8 74.7 30.1 24.2 30.1 72.4 216.6 72.4 216.6z"
                        fill="#333333" p-id="1695"></path>
                    </svg>
                    {{ pageConfig.tel_from_overseas }}
                    <template v-if="pageConfig.id == 8">(Overseas)</template>
                  </div>
                  <div v-if="pageConfig.id == 8">
                    <svg t="1720778366396" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="3517" width="18" height="18"
                      style="margin-right: 5px; transform: translate(0, 3px)" v-if="pageConfig.id == 8">
                      <path
                        d="M502.784 75.264c-245.76 0-443.904 198.144-443.904 443.904S259.584 962.56 502.784 962.56c245.76 0 443.904-198.144 443.904-443.904s-200.192-443.392-443.904-443.392z m289.28 578.048c-6.656 13.824-18.432 20.48-31.744 20.48-4.608 0-9.216 0-13.824-2.048L484.864 552.96c-11.264-4.608-20.48-18.432-20.48-31.744v-245.76c0-18.432 15.872-34.304 34.304-34.304s34.304 15.872 34.304 34.304v225.28l241.152 109.056c17.92 6.656 24.576 25.088 17.92 43.52z"
                        fill="#333333" p-id="3518"></path>
                    </svg>
                    (9am-5pm AEST, Toll free)
                  </div>
                </div>
              </el-dropdown-item>
              <!-- <el-dropdown-item class="pwid"
                ><div class="textl">Melbourn</div>
                <div class="textm">
                  <div>Domestic</div>
                  <div>international</div>
                </div>
                <div class="textR">
                  <div>2-9233-8508</div>
                  <div>+612-9233-8508</div>
                </div></el-dropdown-item
              > -->
              <!-- <el-dropdown-item class="pwid"
                ><div class="textl">Brisbane</div>
                <div class="textm">
                  <div>Domestic</div>
                  <div>international</div>
                </div>
                <div class="textR">
                  <div>2-9233-8508</div>
                  <div>+612-9233-8508</div>
                </div></el-dropdown-item
              > -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="currencyType" v-if="isHome">
          <el-dropdown trigger="click" @command="handleCommand" placement="bottom">
            <span class="el-dropdown-link">
              <img :src="bzTypeList[bzTypeIndex]?.icon" alt="" />
              <span>&nbsp;{{ option }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item.value" v-for="(item, index) in bzTypeList" :key="index">
                <img :src="item.icon" alt="" />
                {{ item.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="isLogin" @click="setLoginStatus(true)" v-if="loginShow">
          Login
        </div>
        <div class="isCard" v-if="!loginShow">
          <el-popover placement="bottom" v-model="loginPopover" width="250" trigger="click">
            <div class="Cardbox">
              <div class="userEmil" v-show="!loginShow">
                <h3>Hi</h3>
                <div class="emil">{{ user_info.email }}</div>
                <div class="aset">
                  <router-link to="/myinfo">Account Settings</router-link>
                  <!-- <span class="editPassword" @click="editPassword"
                    >Change password</span
                  > -->
                  <!-- Modify User Password -->
                  <!-- <router-link to="stroke">MUP</router-link> -->
                </div>
              </div>
              <div class="Signup" @click="setLoginStatus(true)" v-if="loginShow">
                Signup / Login
              </div>
              <div class="itembox" v-if="!loginShow">
                <div class="rout">
                  <router-link to="/orderList?status=1"> My trips</router-link>
                </div>
                <!-- <div class="rout"><router-link to="/">Home</router-link></div>
                <div class="rout">
                  <router-link to="about">About Us</router-link>
                </div>
                <div class="rout">
                  <router-link to="contact"> Contact Us</router-link>
                </div> -->

                <div class="rout" v-if="!loginShow" @click="outLogin">
                  Logout
                </div>
              </div>
            </div>
            <svg slot="reference" t="1678263065586" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="11302" width="24" height="24">
              <path d="M128 128v128h832V128H128z m0 320v128h832V448H128z m0 320v128h832V768H128z" p-id="11303"
                fill="#333333">
              </path>
            </svg>
          </el-popover>
        </div>
      </div>
    </div>
    <login v-if="loginStatus"></login>
    <register v-if="registerStatus"></register>
    <agreement v-if="agreementStatus"></agreement>
    <forgotPassword v-if="forgotPwdStatus"></forgotPassword>
    <resetPassword v-if="resetPwdStatus"></resetPassword>
    <editPassword v-if="editPwdStatus"></editPassword>
  </div>
</template>

<script>
import login from "../login/login.vue";
import register from "../register/register.vue";
import agreement from "../agreement/agreement.vue";
import forgotPassword from "../forgotPassword/forgotPassword.vue";
import resetPassword from "../resetPassword/resetPassword.vue";
import editPassword from "../editPassword/editPassword.vue";
import CNY from "@/assets/img/round-flag-CN.svg";
import USD from "@/assets/img/round-flag-US.svg";
import AUD from "@/assets/img/round-flag-AU.svg";
import EUR from "@/assets/img/round-flag-FR.svg";
import INR from "@/assets/img/inr.svg";
import { mapState } from "vuex";
import handleNotification from "./mixins/handleNotification";

export default {
  mixins: [handleNotification],
  components: {
    login,
    agreement,
    register,
    forgotPassword,
    resetPassword,
    editPassword,
  },
  data() {
    return {
      drawer: false,
      direction: "btt",

      bzTypeIndex: 0,
      istips: sessionStorage.getItem("istips") || true,
      option: "",
      // loginSatus: false,
      // loginShow: true,
      accountNumber: "",
      isHome: false,
      loginPopover: false,
      currencyIcon: {
        AUD,
        CNY,
        USD,
        EUR,
        INR,
      },
    };
  },
  watch: {
    $route: {
      handler(newV) {
        this.isHome = newV.name === "home";
      },
      immediate: true,
    },
    pageConfig: {
      handler(newValue) {
        if (Object.values(newValue).length > 0) {
          this.option = this.pageConfig.default_currency.split(",")[0];
          this.handleCommand(this.option);
          // console.log(this.option);
        }
      },
      immediate: true,
    },
    loginStatus: {
      handler(newVal) {
        if (newVal) {
          console.log(newVal);
          document.title = "Sign in";
        } else {
          document.title = this.pageConfig.site_title;
        }
      },
      immediate: true,
    },
  },

  computed: mapState({
    loginSatus: (state) => state.login.loginStatus,
    loginShow: (state) => state.login.loginShow,
    user_info: (state) => state.login.userInfo,
    loginStatus: (state) => state.login.loginStatus,
    registerStatus: (state) => state.register.registerStatus,
    agreementStatus: (state) => state.register.agreementStatus,
    forgotPwdStatus: (state) => state.register.forgotPwdStatus,
    editPwdStatus: (state) => state.register.editPwdStatus,
    resetPwdStatus: (state) => state.register.resetPwdStatus,
    pageConfig: (state) => state.common.pageConfig,
    bzTypeList: function (state) {
      return (
        state.common.pageConfig?.default_currency
          ?.split(",")
          .map((currency) => {
            // console.log(currency);
            return {
              icon: this.currencyIcon[currency],
              value: currency,
            };
          }) || []
      );
    },
  }),
  methods: {
    tiaozhuan(str) {
      // console.log(str);
      this.drawer = false;
      if (str == 1) {
        this.$router.push("/");
      }
      if (str == 2) {
        window.location.href = "https://travel.aoflights.com/";
      }
      if (str == 3) {
        this.$router.push("/orderSearch");
      }
    },
    editPassword() {
      this.$store.dispatch("register/set_editPwdStatus", true);
    },
    closexy() {
      this.istips = false;
      sessionStorage.setItem("istips", false);
      this.setNotificationTop();
    },
    setLoginStatus(value) {
      this.$store.dispatch("login/set_loginstatus", value);
    },
    getCloseDialog(value) {
      this.$store.dispatch("login/set_loginstatus", value);
    },
    outLogin() {
      this.handleCommand(this.option);
      this.loginPopover = false;
      this.$store.dispatch("login/set_logout");
    },
    // 币种选择
    handleCommand(command) {
      // console.log(command);
      // // console.log("执行了");
      //   // console.log(command, command);
      var storage = window.sessionStorage;
      this.bzTypeIndex = this.bzTypeList.findIndex(
        (item) => item.value === command
      );
      storage.setItem("Currency", command);
      this.$store.dispatch("common/set_Currency", command);
      this.option = command;
    },
    // 卡片显隐
    isCard() { },
  },
};
</script>

<style lang="scss" scoped>
@import "./nav.scss";
</style>
