import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/home"),
  },
  {
    path: "/flights",
    name: "flights",
    component: () => import("@/views/flights/flights"),
    meta: {
      KeepAlive: true
    }
  },
  {
    path: "/orderList",
    name: "orderList",
    component: () => import("@/views/order/orderList.vue"),
  },
  {
    path: "/returnTicket",
    name: "returnTicket",
    component: () => import("@/views/order/returnTicket/index.vue"),
  },

  {
    path: "/changeInfo/:oid?",
    name: "changeInfo",
    component: () => import("@/views/order/changeInfo/changeInfo.vue"),
  },
  {
    path: "/returnInfo/:oid?",
    name: "returnInfo",
    component: () => import("@/views/order/returnInfo/returnInfo.vue"),
  },
  {
    path: "/changeTicket",
    name: "changeTicket",
    component: () => import("@/views/order/changeTicket/index.vue"),
  },
  {
    path: "/orderInfo/:oid?",
    name: "orderInfo",
    component: () => import("@/views/order/orderInfo.vue"),
  },
  {
    path: "/orderSearch",
    name: "orderSearch",
    component: () => import("@/views/order/orderSearch.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about/about"),
  },
  {
    path: "/myinfo",
    name: "myinfo",
    component: () => import("@/views/myinfo/myinfo"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact/contact"),
  },
  {
    path: "/information/:data?",
    name: "information",
    component: () => import("@/views/information/information"),
  },
  
  {
    path: "/pay/:data?",
    name: "pay",
    component: () => import("@/views/pay/pay"),
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import("@/views/loading/loading"),
  },

  {
    path: "/term",
    name: "term",
    component: () => import("@/views/Terms/term")
  },
  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("@/views/Privacy/Privacy")
  },
  {
    path: '*',
    redirect: '/'
  }
];
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.afterEach((to, from) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
})
export default router;
